import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
})

const url = 'https://script.google.com/macros/s/AKfycbz2ryI9gx4_hxqDOVMg5LjKcoCNfSqK608S5HLemEHfVh552WjuVZ2WifIMyrliOHVg/exec'

const messageConfirm = () => {
  return toast.info("Thank you for subscribing to our blog.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class FormMap extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }
 
  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol lg="6" className="px-0 mt-4">
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  PageUrl: this.state.pageUrl,
                  Email: values.email,
                  Subscribe: true,
                }

                try {
                  const response = axios({
                    method: 'get',
                    mode: 'no-cors', 
                    url: `${url}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&email=${encodeURIComponent(values.email)}&subscribe=true`
                  })
                    console.log(response)
                    this.setState({values: '', expired: 'true'})
                    resetForm()
                    messageConfirm()
                    //alert("Submitted.  Thank you!")
                  } catch (e) {
                    console.log(`Axios request failed: ${e}`)
                  }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="8">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <Field
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control font-alt ${
                            touched.email && errors.email ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="nav-link btn-sm-block btn btn-subscribe-color"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : this.props.linktext }
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default FormMap
